import React, { useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner, Button, Row, Col } from 'react-bootstrap';
import useBackendRequest from '../../hooks/useBackendRequest';
import { epstCertActions } from 'store/epstCert';

const EpstAudioRecorder = ({ setError, clearErrors }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudioUrl, setRecordedAudioUrl] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const email = useSelector(state => state.user.userInfo.email);
   const remainingTime = useSelector(state => state.epstCert.remainingTime);

  const numberOfQuestion = useSelector(
    state => state.epstCert.currentQuestion.numberOfQuestion
  );
  const kindCertification = useSelector(
    state => state.epstCert.currentQuestion.kindCertification
  );

  const speakingAnswered = useSelector(
    state => state.epstCert.speakingAnswered
  );


  useEffect(() => {
    const handleTimerEnd = async () => {
        if (recordedAudioUrl) {
            await uploadAudioToServer(recordedAudioUrl);
            setRecordedAudioUrl(''); // Limpia el estado del audio después de enviarlo
        } else {
            setError('audioUrl', {
                type: 'manual',
                message: 'No se grabó ninguna respuesta antes de que se agotara el tiempo.'
            });
        }
    };

    if (remainingTime <= 0) {
        handleTimerEnd();
    }
  }, [remainingTime]);


  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true /*, mimeType: 'audio/ogg' */ });

  const dispatch = useDispatch();
  const { sendRequest } = useBackendRequest();
  const handleStopRecording = e => {
    e.preventDefault();
    stopRecording();
    setIsRecording(false);
  };

  const handleStartRecording = e => {
    e.preventDefault();
    startRecording();
    setIsRecording(true);
  };

  const applyReqResultData = jsonResult => {
    dispatch(epstCertActions.setSpeakingAnswered(true));
    console.log(jsonResult);
  };

  const resolveBackendError = backErrors => {
    console.log(backErrors);
  };

  const uploadAudioToServer = async audioUrl => {
    const audioAnswer = await fetch(audioUrl);
    const audioBlob = await audioAnswer.blob(); // Convert the audio URL to a Blob
    const formData = new FormData();
    formData.append(
      'audioFile',
      audioBlob,
      `${numberOfQuestion}_${email}_audioFile.wav`
    ); // 'audio' is the field name, 'audio.mp3' is the file name
    formData.append('numberOfQuestion', numberOfQuestion);
    formData.append('kindCertification', kindCertification);
    formData.append('email', email);

    await sendRequest(
      {
        url: `${process.env.REACT_APP_API_REMOTE_SERVER}api/epstCertification/answerSpeakingQuestion`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      },
      setIsLoading,
      applyReqResultData,
      resolveBackendError
    );
  };

  const handleRecorder = async data => {
    data.preventDefault();
    if (isRecording) {
      handleStopRecording(data);
    }

    if (!recordedAudioUrl) {
      setError('audioUrl', {
        type: 'manual',
        message: 'Record your answer before continue...'
      });
      return;
    } else {
      clearErrors('audioUrl');
      // Now, you can submit the recordedAudioUrl to your backend
      await uploadAudioToServer(recordedAudioUrl);
    }
    // Optionally, you can reset the recordedAudioUrl and clear form fields here
    setRecordedAudioUrl('');
  };

  useEffect(() => {
    setRecordedAudioUrl('');
  }, [numberOfQuestion]);

  useEffect(() => {
    if (mediaBlobUrl && speakingAnswered) {
      dispatch(epstCertActions.setSpeakingAnswered(true));
    }
    if (mediaBlobUrl) {
      setRecordedAudioUrl(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  return (
    <>
      {!speakingAnswered && (
        <div>
          <Row>
            <Col>
              <h4>Record your answer</h4>
            </Col>
          </Row>

          {status === 'recording' ? (
            <Row>
              <Col>
                <Button
                  onClick={handleStopRecording}
                  variant="dark"
                  className="me-2 mb-1"
                  style={{
                    width: '100%'
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    style={{
                      width: '1rem',
                      height: '1rem',
                      marginRight: '5px'
                    }}
                    role="status"
                    aria-hidden="true"
                    variant="danger"
                  />
                  Stop Recording
                </Button>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Button
                  onClick={handleStartRecording}
                  variant="dark"
                  className="me-2 mb-1"
                  style={{
                    width: '100%'
                  }}
                >
                  Start Recording
                </Button>
              </Col>
            </Row>
          )}
          {recordedAudioUrl && (
            <Row className="mt-3 mb-3">
              <Col>
                <audio controls src={recordedAudioUrl} />
              </Col>
            </Row>
          )}

          {status !== 'recording' && (
            <Row>
              <Col>
                <Button
                  onClick={handleRecorder}
                  variant="secondary"
                  className="me-2 mb-1"
                  style={{
                    width: '100%'
                  }}
                >
                  Send Answer
                </Button>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

EpstAudioRecorder.propTypes = {
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default EpstAudioRecorder;
